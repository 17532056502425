<template>
  <div id="enrollment-app" class="form-horizontal">
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="title-bar">
      <div class="pull-left center-float">
        <h1 id="heading" class="dark-text">
          {{ $t("_add_patient") }}
        </h1>
      </div>
    </div>
    <Form
      v-if="formActive"
      id="Registration"
      key="Registration"
      name="Registration"
      :isEditing="true"
      :loadFormData="true"
    />
    <div ref="patient-info" v-else class="flex-container">
      <div class="patient-success-icon"></div>
      <div >
        <div >
          <h4
            class="text-center"
          >
            {{ $t("AddSuccessfull") }}
          </h4>
          <h4 class="text-center">
            {{ $t("PatientId") }}:
            <a :href="`/Dashboard/Patient/${addedPatientId}`">{{
              addedPatientId
              }}</a>
          </h4>
          <div v-if="isHHCEnabled" class="flex-container-row">
            <div v-if="hasVot" class="margin-vot-right">
              <h4 class="text-center">
                {{ $t("sureadhereUsername") }}: {{ votUserName }}
              </h4>
              <h4 class="text-center">{{ $t("sureadherePIN") }}: {{ votUserPin }}</h4>
            </div>
            <div>
              <h4 class="text-center">
                {{ $t("hhcUsername") }}: {{ loginUserName }}
              </h4>
              <h4 class="text-center">{{ $t("hhcPIN") }}: {{ loginUserPin }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div>
          <Button :label="$t('add_another_case')" :type="'outlined'" v-on:click='this.onAddAnother'/>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../../../shared/components/Form.vue'
import EventBus from '@/app/shared/event-bus.js'
import html2canvas from 'html2canvas'
import { mapActions } from 'vuex'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import Button from '../../../shared/components/Button.vue'
import { isNotNullNorEmpty } from '../../../../utils/utils'

window.html2canvas = html2canvas

export default {
  name: 'App',
  data: self => ({
    formActive: true,
    addedPatientId: '',
    hasVot: false,
    votUserName: '',
    votUserPin: '',
    loginUserName: '',
    loginUserPin: '',
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: '_add_patient',
        link: '',
        class: 'active'
      }
    ],
    isHHCEnabled: (process.env.VUE_APP_HHC_ENABLED === 'true')
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form,
    Breadcrumbs,
    Button
  },
  methods: {
    formSavedSuccessfully (result) {
      this.formActive = false
      this.addedPatientId = result.response.patientId
      this.loginUserName = result.response.patientId
      this.loginUserPin = result.response.loginUserPin

      if (isNotNullNorEmpty(result.response.votUserPin)) {
        this.hasVot = true
        this.votUserName = result.response.votUserName
        this.votUserPin = result.response.votUserPin
      }
    },
    onAddAnother () {
      window.location.href = '/dashboard/enrollment'
    },
    ...mapActions(['setGetFormUrlParams'])
  }
}
</script>

<style lang="scss" scoped>
#enrollment-app {
  background-color: var(--background-bg-color);
}
* {
  margin: revert;
  box-sizing: unset;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-container-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.patient-success-icon{
  background: url("~@/assets/check_circle_green.svg") no-repeat;
  height: 60px;
  width: 60px;
  background-size: 60px, 60px;
}
.text-center{
  text-align: center;
}
.margin-vot-right{
  margin-right: 200px;
  margin-left: 100px;
}
</style>
